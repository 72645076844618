import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import get from 'lodash/get'

// import Video from '../components/Video'
import Layout from '../components/Layout'

class About extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(
      this,
      'props.data.site.siteMetadata.description'
    )

    return (
      <Layout>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[{ name: 'description', content: siteDescription }]}
          title={`Contact | ${siteTitle}`}
        />

        <section>
        <div className="row" style={{
            // width: '100%',
            // display: 'flex',
            // flexDirection: 'row',
            // textAlign:'center',
            marginTop:'1rem',
          }}
          >
            <div className="col-xs">
              <div className="box-row">
                <h3 className="skills">Software</h3>
                <p>
                C++, Python, Java, Perl, Bash, CUDA, Qt, Linux, UNIX, Docker, Git
                </p>
              </div> 
            </div>

            <div className="col-xs">
              <div className="box-row">
                <h3 className="skills">Full Stack</h3>
                <p>
                React, JavaScript, HTML, CSS, Flask, Node, RESTful, MySQL, NoSQL, Redis, NGINX, Postman, Heroku
                </p>
              </div> 
            </div>

            <div className="col-xs">
            <div className="box-row">
                <h3 className="skills">AI/Comp-Vis</h3>
                <p>
                TensorFlow, Bazel, Keras, Numpy, Pandas, Matplotlib, OpenCV, Deepstream, Gstreamer
                </p>
              </div> 
            </div>

            <div className="col-xs">
            <div className="box-row">
                <h3 className="skills">Networking</h3>
                <p>
                HTTP/s, WebSocket, TCP/IP, TLS, OSI layer, DHCP, CDN, DNS, VPCs, VPN, load balancing                </p>
              </div> 
            </div>


            

            {/* <div className="col-sm">
              <div className="box-row skills">
                <h3>Cloud</h3>
                <p>
                  AWS, GCP, Microsoft Azure
                </p>
              </div> 
            </div> */}

            

            {/* <div className="col-xs">
            <div className="box-row">
                <h3 className="skills">Hardware</h3>
                <p>
                  FPGA Design, System Verilog, VHDL, SoC RTL Design + Validation
                </p>
              </div> 
            </div> */}
            
            {/* <div className="col-xs">
              <div className="box-row">
                <h3 className="skills">Medical Device</h3>
                <p>
                  HIPAA/GDPR compliance, FDA, QMS, ISO 13485
                </p>
              </div> 
            </div> */}

          </div>

        <div className="row" style={{
            // width: '100%',
            // display: 'flex',
            // flexDirection: 'row',
            // textAlign:'center',
            marginTop:'1rem',
          }}
          >
            <div className="col-xs">
              <div className="box-row">
                <h3 className="skills">AWS</h3>
                <p>
                S3, EC2, RDS, ECS, SNS, Redshift, DynamoDB, VPC, Lambda, IAM, SageMaker
                </p>
              </div> 
            </div>

            <div className="col-xs">
              <div className="box-row">
                <h3 className="skills">Project Mgmt</h3>
                <p>
                Jira, Confluence, Bitbucket, CI/CD, Taiga.io, Agile, Scrum, Kanban
                </p>
              </div> 
            </div>

            <div className="col-xs">
              <div className="box-row">
                <h3 className="skills">Digital Marketing</h3>
                <p>
                Google, Facebook, LinkedIn Ads, Hubspot, Crazy Egg, Google Analytics, Intercom
                </p>
              </div> 
            </div>

            <div className="col-xs">
              <div className="box-row">
                <h3 className="skills">Design/Tools/CMS</h3>
                <p>
                Proto.io, Webflow, Wordpress, Balsamiq, Adobe Illustrator, Power BI
                </p>
              </div> 
            </div>
            
          </div>

          
        </section>
       
        {/* <Video
            videoSrcURL="https://youtu.be/OH0cKOMNKFk"
            //videoSrcURL="https://www.youtube.com/embed/videoseries?list=PLx0sYbCqOb8TBPRdmBHs5Iftvv9TPboYG"
            videoTitle ="My 15 Minutes of Fame"
        /> */}

        
      </Layout>
    )
  }
}

export default About

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
